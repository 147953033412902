<template>

<b-container>

  <b-form @submit="submit">

    <b-form-group id="input-group-urls" :label="t('multiadd_urls')" label-for="input-urls">

      <b-form-textarea id="textarea" v-model="multiadd_urls" placeholder="http://" rows="10" required trim></b-form-textarea>
    </b-form-group>

    <b-button variant="success" type="submit">{{ t('save') }}</b-button>

  </b-form>

  <p class="notif" v-if="multiadd_queuelength > 0">
    <b-spinner label="Spinning"></b-spinner>
    <span>{{ multiadd_queuelength }} {{ t('waiting') }}</span>
  </p>

  <div v-if="multiadd_errors.length" class="my-5">
    <h4>{{ t('errors') }}</h4>
    <ul>
      <li v-for="e in multiadd_errors" :key="e.url">
        {{ e.url }} ({{ e.text }})
      </li>
    </ul>
  </div>

</b-container>

</template>

<script>

//import InputFolder from '@/components/InputFolder.vue'

export default {
  components: {
  //  InputFolder,
  },
  data: function() {
    return {
      multiadd_urls: '',
      multiadd_folder_id: 0,
      multiadd_errors: [],
      multiadd_queuelength: 0,
      multiadd_duplicatelength: 0,
    }
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
    changeFolder: function($event) {
      this.multiadd_folder_id = $event
    },
    submit: function() {

      // set an array of urls with each line
      let urls = this.multiadd_urls.split('\n')

      // remove duplicates
      let uniqueUrls = urls.filter((u, index) => {
        return urls.indexOf(u) === index;
      });

      // reset error number
      this.multiadd_errors = []

      // set the number of item to save
      this.multiadd_queuelength = uniqueUrls.length

      // save all
      for (var k in uniqueUrls) {
        let data = { url: uniqueUrls[k], folder_id: this.multiadd_folder_id }
        this.$http.post('/bookmarks', data).catch((error) => {
          this.multiadd_errors.push({
            url: data.url,
            text: error.response.data.message
          })
        }).then(() => {
          this.multiadd_queuelength -= 1
          if (this.multiadd_queuelength == 0) {
            this.$store.dispatch('updateNotif', this.t('bookmark_added'))
            this.$store.dispatch('fetchBookmarks');
          }
        });
      }
    },
  },
}
</script>

<style scoped lang="scss">

.notif {
  margin: 2em;
  text-align: center;
  font-weight: bold;
  span {
    margin: 0 .6em;
  }
}
</style>

<template>

<b-container class="vertical-space">
  <b-tabs content-class="my-3">
    <b-tab :title="t('multiadd_title')" active>
      <Multiadd/>
    </b-tab>
    <!--
    <b-tab :title="t('quickadd_title')">
      <Quickadd/>
    </b-tab>
    -->
  </b-tabs>
</b-container>

</template>

<script>

import Multiadd from '@/components/configuration/Multiadd.vue'
//import Quickadd from '@/components/configuration/Quickadd.vue'

export default {
  components: {
    Multiadd,
//    Quickadd,
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
  }
}
</script>

<style scoped lang="scss">

</style>
